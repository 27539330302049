// RoleOption
import request from '@/utils/request';
export function categoriesList(params) {
    return request({ url: `/categories/`, method: 'get', params })
  }

  export function categoriesCreate(data) {
    return request({ url: `/categories/`, method: 'post', data })
  }

  export function categoriesUpdate(data) {
    return request({ url: `/categories/${data.id}/`, method: 'put', data })
  }

  export function categoriesDelete(data) {
    return request({ url: `/categories/${data.id}/`, method: 'delete', data })
  }
