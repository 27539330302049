<template>
  <div>
    <a-card :title="$t('产品分类')">
      <a-row gutter="16">
        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px">
          <a-input v-model="searchForm.search" :placeholder="$t('分类名称')" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">{{ $t("查询") }}</a-button>
        </a-col>

        <a-col :span="24" :md="6" :xl="8" style="margin-bottom: 12px">
          <a-button-group>
            <a-button icon="file-excel" @click="downloadTemplate">{{ $t("模板下载") }}</a-button>
            <a-upload name="file" :showUploadList="false" :customRequest="importExcel">
              <a-button icon="upload">{{ $t("导入") }}</a-button>
            </a-upload>
            <a-button icon="download" @click="exportExcel">{{ $t("导出") }}</a-button>
            <a-popconfirm :title="$t('确定删除吗?')" @confirm="batchDestroy">
              <a-button type="danger" icon="delete">{{ $t("批量删除") }}</a-button>
            </a-popconfirm>
          </a-button-group>
        </a-col>
        <div style="margin-bottom: 12px; float: right">
          <a-button type="primary" icon="plus" style="margin: 0 8px" @click="openFormModal(form)">
            {{ $t("新增分类") }}
          </a-button>
        </div>
      </a-row>
      <a-row>
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :dataSource="items"
          :loading="loading"
          :pagination="pagination"
          :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          @change="tableChange"
        >
          <div slot="action" slot-scope="value, item">
            <a-button-group>
              <a-button icon="edit" size="small" @click="openFormModal(item)">{{ $t("编辑") }}</a-button>
              <a-popconfirm :title="$t('确定删除吗?')" @confirm="destroy(item.id)">
                <a-button type="danger" icon="delete" size="small">{{ $t("删除") }}</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>

    <form-modal v-model="visible" :form="targetItem" @create="create" @update="update" />
    <a-modal v-model="importLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />{{ $t("正在导入中, 请等待...") }}</div>
    </a-modal>
  </div>
</template>

<script>
import { categoriesList, categoriesDelete } from "@/api/categories";
import { exportExcel } from "@/utils/excel";
import { categoriesExport } from "@/api/export";
import { categoriesTemplate, categoriesImport } from "@/api/import";
import columns from "./columns.js";

export default {
  name: "Categories",
  components: {
    FormModal: () => import("./FormModal.vue"),
  },
  data() {
    return {
      columns,
      searchForm: { search: "", page: 1, ordering: undefined },
      pagination: { current: 1, total: 0, pageSize: 16 },
      loading: false,
      items: [],

      visible: false,
      targetItem: {},
      form: {
        temperature_control: false,
        humidity_control: false,
        inventory_warning: false,
        quality_control: false,
        stock_out_strategy: "fifo",
      },
      importLoading: false,
      unitItems: [],
      selectedRowKeys: [],
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    initialize() {
      this.searchForm.warehouse = this.currentWarehouse;
      this.list();
    },
    list() {
      this.loading = true;
      categoriesList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    create(item) {
      this.items.splice(0, 0, item);
    },
    update(item) {
      this.items.splice(
        this.items.findIndex((i) => i.id == item.id),
        1,
        item
      );
    },
    destroy(id) {
      categoriesDelete({ id }).then(() => {
        this.items.splice(
          this.items.findIndex((item) => item.id == id),
          1
        );
        this.$message.success(this.$t("删除成功"));
      });
    },
    batchDestroy() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning(this.$t("请选择要删除的数据"));
        return;
      }

      for (const key of this.selectedRowKeys) {
        this.destroy(key);
      }
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    openFormModal(item) {
      this.targetItem = { ...item };
      this.visible = true;
    },
    exportExcel() {
      categoriesExport(this.searchForm)
        .then((resp) => {
          exportExcel(resp, this.$t("产品分类列表"));
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    downloadTemplate() {
      categoriesTemplate()
        .then((resp) => {
          exportExcel(resp, this.$t("产品分类导入模板"));
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    importExcel(item) {
      let data = new FormData();
      data.append("file", item.file);
      this.importLoading = true;
      setTimeout(() => {
        categoriesImport(data)
          .then(() => {
            this.$message.success(this.$t("导入成功"));
            this.list();
          })
          .catch((err) => {
            alert(this.$t("导入错误:") + "\n" + err.response.data.join("\n"));
          })
          .finally(() => {
            this.importLoading = false;
          });
      }, 1000);
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped></style>
